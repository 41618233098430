import React from "react";
import style from './index.module.less'
import { contactLogo, emailBlackLogo, discordLogo, redditLogo } from '@/images/support/index'

import {
    youtube,
    twitter,
    ins,
    reddit
} from '@/images/air/index'
import { useIntl } from "react-intl"
import getCountry from '@/utils/getCountry.js'
import { useGTM } from '@/hooks/useGTM.js'

const Contact = () => {
    const intl = useIntl()
    const region = getCountry()
    return (
        <div className={style.contact}>
            <h2 className={style.contactTitle}>{intl.formatMessage({ id: "footerList.contact", defaultMessage: "Contact Us" })}</h2>

            <div className={style.contactElse}>
                {region == 'jp' ? <div className={style.contactElseItem}>
                    <img src={discordLogo} />
                    <div className={style.contactElseItemTitle}>{intl.formatMessage({ id: "footerList.contact", defaultMessage: "Community Forum" })}</div>
                    <a href={process.env.url_discord_jp} target='_blank'>{intl.formatMessage({ id: "support_contact_ask", defaultMessage: "Ask questions or search immediately" })}</a>
                </div>
                    : <div className={style.contactElseItem}>
                        <img src={redditLogo} />
                        <div className={style.contactElseItemTitle}>{intl.formatMessage({ id: "support_join_title", defaultMessage: "Join Us" })}</div>
                        <div onClick={()=> useGTM('support_click', 'Join Us', '', 'Contact Us')}>
                             <a href={process.env.url_reddit} target='_blank'>{intl.formatMessage({ id: "support_join_desc", defaultMessage: "Join the XREAL community, connect with users!" })}</a>
                        </div>
                       
                    </div>}

                <div className={style.contactElseItem}>
                    <img src={emailBlackLogo} />
                    <div className={style.contactElseItemTitle}>{intl.formatMessage({ id: "support_contact_email", defaultMessage: "Contact by Email" })}</div>
                    <div className={style.contactElseItemText} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "contact_email" }) }}></div>
                </div>
            </div>
            <div className={style.media}>
                <div className={style.mediaTitle}>{intl.formatMessage({ id: "support_touch", defaultMessage: "You can also get in touch with us in the following ways" })}</div>
                <div className={style.mediaBox}>
                    <img src={youtube} onClick={() => {
                        window.open(region == 'jp' ? process.env.url_youtube_jp : process.env.url_youtube);
                        useGTM('support_click', 'youtube', '', 'Contact Us')

                    }} />
                    <img src={twitter} onClick={() => {
                        window.open(region == 'jp' ? process.env.url_twitter_jp : process.env.url_twitter);
                        useGTM('support_click', 'twitter', '', 'Contact Us')
                    }} />
                    <img src={ins} onClick={() => {
                        window.open(region == 'jp' ? process.env.url_ins_jp : process.env.url_ins);
                        useGTM('support_click', 'ins', '', 'Contact Us')

                    }} />
                    <img src={reddit} onClick={() => {
                        window.open(process.env.url_reddit);
                        useGTM('support_click', 'reddit', '', 'Contact Us')

                    }} />
                </div>
            </div>
        </div>
    )
}

export default Contact