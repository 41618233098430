import React from "react";
import style from './index.module.less'
import {
    faqArrow, supportFaq1, supportFaq3,
    supportFaq2,
} from '@/images/support/index'
import useNavigate from "@/hooks/useNavigate"
import useNavigateLink from "@/hooks/useNavigateLink"
import { useIntl } from "react-intl"
import useGa from "@/hooks/useGa"
import { useGTM } from '@/hooks/useGTM.js'

const FAQ = () => {
    const intl = useIntl()
    const faqList = [
        {
            title: intl.formatMessage({ id: "products_text", defaultMessage: "Product" }),
            img: supportFaq1,
            link: "/faq/#products",
            cate: 'support_FAQ_product',
            ga_name:"Products"
        },
        {
            title: intl.formatMessage({ id: "support_faq_order", defaultMessage: "Order Help" }),
            img: supportFaq3,
            link: "/faq/#order-help",
            cate: 'support_FAQ_order',
            ga_name:"Order Help"
        },
        {
            title: intl.formatMessage({ id: "after_sale", defaultMessage: "After Sale" }),
            img: supportFaq2,
            link: "/faq/#after-sales",
            cate: 'support_FAQ_after_sales',
            ga_name:"Aftersales"
        }
    ]

    const getLinkUrl = (link) => {
        const url = useNavigateLink(link)
        return url
    }

    return (
        <div className={style.faq}>
            <h2 className={style.faqTitle}>
                {intl.formatMessage({ id: "FAQ_text", defaultMessage: "FAQ" })}</h2>
            <div className={style.faqBox}>
                {faqList.map((item, index) => (
                    <div key={`faq${index}`} className={style.faqBoxItem} onClick={() => {
                        useGa('support', item.cate, 'Click')
                        useNavigate(item.link)
                        useGTM('support_click', item.ga_name, '', 'FAQ')
                    }}>
                        <a href={getLinkUrl(item.link)} onClick={event => event.preventDefault()}>
                            <div className={style.faqBoxItemTitle}>
                                {item.title}
                            </div>
                            <div className={style.faqBoxItemImg}>
                                <div className={style.faqBoxItemImgBg} style={{ backgroundImage: `url(${item.img})` }}></div>
                                <img src={faqArrow} />
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FAQ