import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { SupportPic } from '@/images/support/index'
import style from './index.module.less'
import { Product, PlayPart, Contact, FAQ } from '@/components/Support/index'
import { useIntl } from "react-intl"
import { useInView } from "react-intersection-observer"


const Index = () => {
  const intl = useIntl()
  const [headerRef, headerRefInView] = useInView({ threshold: 0 });
  const [bodyRef, bodyRefInView] = useInView({ threshold: 0 });

  return (
    <Layout menuBlack={!headerRefInView && bodyRefInView ? true : false}>
      <SEO page="Support" />
      <div className={style.support} ref={headerRef} style={{ backgroundImage: `url(${SupportPic})` }}>

        {intl.formatMessage({ id: "support_text", defaultMessage: "Support" })}
      </div>
      <div ref={bodyRef}>
        <Product />
      <PlayPart />
      <FAQ />
      <Contact /> 
      </div>
     
    </Layout>
  )
}

export default Index
