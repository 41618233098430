import React, { useState, useRef } from "react";
import style from './index.module.less'
import {
    playBtn,
    play1,
    play2,
    play3,
    play4,
    play5,
    play6,
    play7,
    play8,
    playImg1,
    playImg2,
    playImg3,
    playImg4,
    playImg5,
    playImg6,
    playImg7,
    playImg8

} from '@/images/support/index'
import { closeIcon } from '@/images/arlab/index'
import { useIntl } from "react-intl"
import useGa from "@/hooks/useGa"
import { useGTM } from '@/hooks/useGTM.js'

const PlayPart = () => {
    const intl = useIntl()
    const [videoPlay, setVideoPlay] = useState(false)
    const videoRef = useRef()
    const playList = [
        // {
        //     title: intl.formatMessage({ id: "support_video_8", defaultMessage: 'XREAL Air 2 and Air 2 Pro User Guide' }),
        //     img: playImg8,
        //     video: play8,
        //     cate: 'support_video_air2Use',
        //     time: "05:01"
        // },

        {
            title: intl.formatMessage({ id: "support_video_1", defaultMessage: 'For Android Users' }),
            img: playImg1,
            video: play1,
            cate: 'support_video_ForAndroidUsers',
            time: "01:17"
        },
        {
            title: intl.formatMessage({ id: "support_video_2", defaultMessage: 'For iPhones& iPads with Apple Lightning port' }),
            img: playImg2,
            video: play2,
            cate: 'support_video_ForiPhone',
            time: "01:21"
        },
        {
            title: intl.formatMessage({ id: "support_video_3", defaultMessage: 'For MacBook with M1-M2 chips' }),
            img: playImg3,
            video: play3,
            cate: 'support_video_ForMac',
            time: "01:06"
        }
        ,
        {
            title: intl.formatMessage({ id: "support_video_4", defaultMessage: 'For Steam Deck Users' }),
            img: playImg4,
            video: play4,
            cate: 'support_video_ForSteamDeck',
            time: "00:50"
        },
        {
            title: intl.formatMessage({ id: "support_video_5", defaultMessage: 'For PlayStation 5 Users' }),
            img: playImg5,
            video: play5,
            cate: 'support_video_ForPS5',
            time: "01:09"
        },
        {
            title: intl.formatMessage({ id: "support_video_6", defaultMessage: 'For Xbox X-S Series Users' }),
            img: playImg6,
            video: play6,
            cate: 'support_video_ForXbox',
            time: "01:26"
        },
        {
            title: intl.formatMessage({ id: "support_video_7", defaultMessage: 'For Nintendo Switch Users' }),
            img: playImg7,
            video: play7,
            cate: 'support_video_ForSwitch',
            time: "01:18"
        }

    ]
    const [showVideo, setShowVideo] = useState('')
    const openVideo = (video) => {
        setShowVideo(video)
    }
    const playVideo = () => {
        setVideoPlay(true)
        videoRef.current.play()
        useGTM('support_click', 'play_XREAL Air 2 and Air 2 Pro User Guide', '', 'New User Guide')
    }
    return (
        <div className={style.play}>
            <h2 className={style.playTitle}>{intl.formatMessage({ id: "support_product_user_guide", defaultMessage: "New User Guide" })}</h2>
            <div className={style.playVideo}>
                {!videoPlay &&
                    <div className={style.playVideoCover}>
                        <div className={style.playVideoCoverText}>
                            XREAL Air 2 and Air 2 Pro User Guide
                            <div>05:01</div>
                        </div>
                        <img style={{ opacity: videoPlay ? 0 : 1 }} src={playBtn} className={style.playIcon} onClick={() => playVideo()} />
                    </div>
                }
                <video
                    autoPlay={false}
                    width="100%"
                    controls={videoPlay}
                    playsInline
                    poster={playImg8}
                    ref={videoRef}
                >
                    <source src={play8} type="video/mp4" />
                </video>

            </div>
            <h2 className={style.playTitle}>{intl.formatMessage({ id: "support_product_play", defaultMessage: "Play Different" })}</h2>
            <div className={style.playBox}>
                {playList.map((item, index) => (
                    <div className={style.playBoxItem} key={`supportBox${index}`} >
                        <div className={style.playBoxItemImg} onClick={() => {
                            useGa('support', item.cate, 'Click')
                            useGTM('support_click', `play_${item.title}`, '', 'Play Different')

                            openVideo(item.video)
                        }}>
                            <div className={style.playBoxItemImgBg}
                                style={{ backgroundImage: `url(${item.img})` }}
                            ></div>

                            <div className={style.playBoxItemImgBg}></div>
                            <img src={playBtn} />
                        </div>
                        <div className={style.playBoxItemText}>{item.title}
                            <div className={style.playBoxItemTextTime}>{item.time}</div>
                        </div>

                    </div>
                ))}
                <div className={style.place} ></div>
                <div className={style.place} ></div>
                <div className={style.place} ></div>
                <div className={style.place} ></div>

            </div>
            {!!showVideo &&
                <div className={style.bigPicBox} >
                    <div className={style.bigPicVideo}>
                        <video autoPlay width="100%" controls playsInline>
                            <source src={showVideo} type="video/mp4" />
                        </video></div>

                    <img src={closeIcon} className={style.closeBtn} onClick={() => setShowVideo('')} />
                </div>
            }
        </div>
    )
}

export default PlayPart